import { useQuery } from "@apollo/client";
import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useLocale } from "../locale";
import {
  LoyaltyListGiftCatalog,
  LoyaltyListGiftCatalogVariables,
} from "./__generated__/LoyaltyListGiftCatalog";

const LoyaltyListGiftCatalogQuery = gql`
  query LoyaltyListGiftCatalog($token: String!, $language: String) {
    loyaltyListGiftCatalog(token: $token, language: $language) {
      items {
        id
        label
        image
        pricePoints
        value
        currency
      }
    }
  }
`;

export const useLoyaltyListGiftCatalog = ({
  skip,
}: { skip?: boolean } = {}) => {
  const token = useUserToken();
  const locale = useLocale();
  const res = useQuery<LoyaltyListGiftCatalog, LoyaltyListGiftCatalogVariables>(
    LoyaltyListGiftCatalogQuery,
    {
      skip: skip || !token,
      variables: {
        token,
        language: locale,
      },
    },
  );

  return {
    ...res,
    catalog: res.data && res.data.loyaltyListGiftCatalog,
  };
};
