import React, { useState } from "react";
import { withPage } from "../../lib/withPage";
import { Layout } from "../../components/Layout";
import {
  Loader,
  LoyaltyCards,
  Wrapper,
} from "@royalcanin-be-partner-portal/ui-kit";
import { useLoyaltyListGiftCatalog } from "../../lib/hooks/useLoyaltyListGiftCatalog";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useLoyaltyRetrieveBalance } from "../../lib/hooks/useLoyaltyRetrieveBalance";
import { useLoyaltyListGiftPurchase } from "../../lib/hooks/useLoyaltyListGiftPurchase";

const LoyaltyPurchasePage = () => {
  const { data: currentPoints, loading: retrieveBalanceLoading } =
    useLoyaltyRetrieveBalance();
  const { catalog, loading } = useLoyaltyListGiftCatalog();
  const { purchase, loading: purchaseLoading } = useLoyaltyListGiftPurchase();
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const isLoading = retrieveBalanceLoading || loading;
  return (
    <Layout>
      <Wrapper>
        <LoyaltyCards
          gifts={
            catalog?.items.map((item) => ({
              amount: (
                <FormattedNumber
                  value={Number(item.value)}
                  style="currency"
                  currency={item.currency}
                  minimumFractionDigits={0}
                  maximumFractionDigits={2}
                />
              ),
              company: item.label,
              image: item.image,
              points: item.pricePoints,
              userPoints: currentPoints,
              onPurchase: async () => {
                setIsSuccess(false);
                setError(null);
                const { errors } = await purchase(item.id);
                if (errors) {
                  setError(errors[0].message);
                  return;
                } else {
                  setIsSuccess(true);
                }
              },
              isSubmitting: purchaseLoading,
            })) ?? []
          }
          error={error}
          success={
            isSuccess && (
              <FormattedMessage
                id="loyalty.purchase.success"
                defaultMessage="Votre achat a bien été effectué"
              />
            )
          }
        />
        {isLoading && <Loader />}
      </Wrapper>
    </Layout>
  );
};

export default withPage(LoyaltyPurchasePage);
