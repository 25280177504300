import { useMutation } from "@apollo/client";
import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { LoyaltyRetrieveBalanceQuery } from "./useLoyaltyRetrieveBalance";
import {
  LoyaltyGiftPurchase,
  LoyaltyGiftPurchaseVariables,
} from "./__generated__/LoyaltyGiftPurchase";

const LoyaltyGiftPurchaseMutation = gql`
  mutation LoyaltyGiftPurchase($token: String!, $productId: String!) {
    loyaltyGiftPurchase(token: $token, productId: $productId)
  }
`;

export const useLoyaltyListGiftPurchase = () => {
  const token = useUserToken();
  const [mutate, res] = useMutation<
    LoyaltyGiftPurchase,
    LoyaltyGiftPurchaseVariables
  >(LoyaltyGiftPurchaseMutation);

  return {
    ...res,
    purchase: async (productId: string) => {
      const result = await mutate({
        variables: {
          token,
          productId,
        },
        errorPolicy: "all",
        refetchQueries: [LoyaltyRetrieveBalanceQuery],
        awaitRefetchQueries: true,
      });
      return {
        ...result,
        success: result.data?.loyaltyGiftPurchase,
      };
    },
  };
};
