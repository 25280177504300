import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import {
  LoyaltyRetrieveBalance,
  LoyaltyRetrieveBalanceVariables,
} from "./__generated__/LoyaltyRetrieveBalance";

export const LoyaltyRetrieveBalanceQuery = gql`
  query LoyaltyRetrieveBalance($token: String!) {
    customLoyaltyRetrieveBalance(token: $token) {
      user_total_points
    }
  }
`;

export const useLoyaltyRetrieveBalanceQuery = () => {
  const token = useUserToken();
  return {
    query: LoyaltyRetrieveBalanceQuery,
    skip: !token,
    variables: {
      token,
    },
  };
};

export const useLoyaltyRetrieveBalance = () => {
  const { variables, query, skip } = useLoyaltyRetrieveBalanceQuery();
  const res = useQuery<LoyaltyRetrieveBalance, LoyaltyRetrieveBalanceVariables>(
    query,
    {
      skip,
      variables,
    },
  );

  const loyaltyRetrieveBalance =
    (res.data &&
      res.data.customLoyaltyRetrieveBalance &&
      res.data.customLoyaltyRetrieveBalance.user_total_points) ||
    "0";

  return {
    ...res,
    data: parseInt(loyaltyRetrieveBalance, 10),
  };
};
